import { cn } from '@/lib';
import { CircleBackslashIcon } from '@radix-ui/react-icons';
import { useCallback, useState } from 'react';
import ReactPlayer from 'react-player';
import { Typography } from '../display';

type PropType = {
  inView?: boolean;
};

interface ISVGProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  className?: string;
}

const LoadingSpinner = ({ size = 24, className, ...props }: ISVGProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      {...props}
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='1'
      strokeLinecap='round'
      strokeLinejoin='round'
      className={cn('animate-spin text-content2', className)}
    >
      <path d='M21 12a9 9 0 1 1-6.219-8.56' />
    </svg>
  );
};

export const LazyVideo: React.FC<React.ComponentProps<typeof ReactPlayer> & PropType> = (props) => {
  const { url, inView = false, className, ...rest } = props;
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);

  const serError = useCallback(() => {
    if (inView) setHasError(true);
  }, [inView, setHasError]);

  if (hasError) {
    return (
      <div className={cn('flex w-full justify-center items-center bg-content1/40')}>
        <div
          className={cn(className, 'w-[25rem] h-[80vh] flex flex-col space-y-24 justify-center items-center')}
        >
          <CircleBackslashIcon className='text-chalk w-28 h-auto' />
          <Typography className='text-3xl text-chalk'>Error loading an video :(</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(className)}>
      {loading && (
        <div className={cn('relative')}>
          <div className={cn(className, 'absolute top-[23rem] left-[48%]')}>
            <LoadingSpinner size={130} />
          </div>
        </div>
      )}

      <ReactPlayer
        onReady={(e: any) => setLoading(e?.player?.isLoading)}
        width={'100%'}
        onError={serError}
        height={'100%'}
        url={url}
        loop
        {...rest}
      />
    </div>
  );
};

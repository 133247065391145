import { cn } from '@/lib';
import { CircleBackslashIcon } from '@radix-ui/react-icons';
import { useCallback, useRef, useState } from 'react';
import { Typography } from '../display';

const PLACEHOLDER_SRC = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D';

type PropType = {
  inView?: boolean;
  imgClass?: string;
};

export interface ISVGProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  className?: string;
}

export const LoadingSpinner = ({ size = 24, className, ...props }: ISVGProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      {...props}
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='1'
      strokeLinecap='round'
      strokeLinejoin='round'
      className={cn('animate-spin text-content2', className)}
    >
      <path d='M21 12a9 9 0 1 1-6.219-8.56' />
    </svg>
  );
};

export const LazyImage: React.FC<React.ComponentProps<'img'> & PropType> = (props) => {
  const { src, imgClass, inView = false, className, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const image = useRef<HTMLImageElement>(null);
  const [width, setWidth] = useState('0px');
  const height = '80vh';

  const setLoaded = useCallback(() => {
    const naturalWidth = image.current?.naturalWidth;
    const naturalHeight = image.current?.naturalHeight;
    const actualHeight = image.current?.clientHeight;

    if (naturalWidth && naturalHeight && actualHeight) {
      const actualWidth = (naturalWidth * actualHeight) / naturalHeight;
      setWidth(`${actualWidth}px`);
    }

    if (inView) setLoading(false);
  }, [inView, setLoading]);

  const serError = useCallback(() => {
    if (inView) setHasError(true);
  }, [inView, setHasError]);

  //   if (loading) {
  //     return (
  //       <div className={cn('flex w-full justify-center items-center bg-content1/40')}>
  //         <div className={cn('w-[25rem] h-[80vh] flex flex-col space-y-24 justify-center items-center')}>
  //           <LoadingSpinner size={130} />
  //            </div>
  //       </div>
  //     );
  //   }

  if (hasError) {
    return (
      <div className={cn('flex w-full justify-center items-center bg-content1/40')}>
        <div
          className={cn(className, 'w-[25rem] h-[80vh] flex flex-col space-y-24 justify-center items-center')}
        >
          <CircleBackslashIcon className='text-chalk w-28 h-auto' />
          <Typography className='text-3xl text-chalk'>Error loading an image :(</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(className)}>
      {loading && (
        <div className={cn('relative')}>
          <div className={cn(className, 'bg-content1 flex justify-center items-center')}>
            <LoadingSpinner size={130} />
          </div>
        </div>
      )}
      {/* biome-ignore lint/a11y/useAltText: <explanation> */}
      <img
        {...rest}
        ref={image}
        className={`w-[${width}] h-[${height}]`}
        onLoad={setLoaded}
        onLoadStart={() => setLoading(true)}
        onError={serError}
        src={inView ? src : PLACEHOLDER_SRC}
        data-src={src}
      />
    </div>
  );
};

import { envVars } from '@/config';
import type { IGetOnecoreRes } from '@/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchCustomBaseQuery } from '../fetcher';

export const oneCoreApi = createApi({
  reducerPath: 'oneCoreApi',
  baseQuery: fetchCustomBaseQuery({
    baseUrl: envVars.backendUrl,
    mock: false,
  }),
  tagTypes: ['onecore'],
  endpoints: (builder) => ({
    getOneCoreConfig: builder.query<IGetOnecoreRes, void>({
      query: () => ({
        url: '/history/onecore-config',
        isProtected: false,
        forceMock: false,
      }),
    }),
  }),
});

export const { useGetOneCoreConfigQuery } = oneCoreApi;

import { Typography } from '@/core/ui';
import type { ICompetitionMembers } from '@/types';
import { useTranslation } from 'react-i18next';
import { President } from './president';

interface IProps {
  president: ICompetitionMembers['president_info'];
  competitionName: string;
}
export const Galleria = ({ president, competitionName }: IProps) => {
  const { t } = useTranslation('translation');

  return (
    <div className='grid grid-cols-2 bg-content1 h-screen'>
      <div className='col-span-4 flex flex-col mt-[71px] ml-[97px] space-y-[93px]'>
        <div className='flex flex-col space-y-2'>
          <Typography className='text-chalk text-2xl leading-[28.5px] tracking-[1.5px] font-medium'>
            {competitionName}
          </Typography>
          <Typography className='text-chalk text-3xl leading-[28.5px] tracking-[1.5px] font-semibold'>
            {t('competition.member.president')}
          </Typography>
        </div>
        <President data={president} />
      </div>
    </div>
  );
};

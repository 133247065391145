import { ROUTES } from '@/config';
import { Stack, Trophy } from '@/core/blocks';
import { Skeleton, Typography } from '@/core/ui';
import { useAppDispatch, useAppSelector } from '@/store';
import { setModalityId } from '@/store/actions';
import { API } from '@/store/api';
import type { ITrophy } from '@/types';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { TrophyLoading } from './loading';

export const CompetitionList = () => {
  const { t, i18n } = useTranslation('translation');
  const { modalityId } = useParams();
  const [page, setPage] = useState(1);
  const [competitions, setCompetitions] = useState<ITrophy[]>([]);
  const trophyCount = useAppSelector((s) => s.competition.trophyCount);
  const dispatch = useAppDispatch();

  const { error, isLoading, isFetching, data } = API.competitionApi.useGetCompetitionsQuery(
    {
      modalityId: Number.parseInt(modalityId as string, 10),
      locale: i18n.language,
      page,
    },
    { skip: !modalityId || !i18n.language },
  );

  useEffect(() => {
    if (modalityId) {
      dispatch(setModalityId(Number.parseInt(modalityId as string, 10)));
    }

    setCompetitions([]);
    setPage(1);
  }, [modalityId]);

  useEffect(() => {
    if (data && page === data.pagination.current_page) {
      setCompetitions((prev) => [...prev, ...data.competitions]);
    }
  }, [data]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
    const isNotLastPage = data && page < data.pagination.last_page;
    const scrollThresholdReached = scrollLeft + clientWidth >= scrollWidth - 50;

    if (isNotLastPage && scrollThresholdReached && !isFetching) {
      setPage((prev) => prev + 1);
    }
  };

  if (error) {
    return <p>Oh no, there was an error</p>;
  }

  if (isLoading && page === 1) return <TrophyLoading />;

  return (
    <div className='absolute flex flex-row w-full pl-24 bottom-0 items-end'>
      <div className='flex flex-row space-x-4'>
        <div className='flex flex-col items-center w-full space-y-4'>
          <Typography className='uppercase rotate-180 text-3xl font-semibold [writing-mode:vertical-lr]'>
            {t('home.trophyText', { trophyCount })}
          </Typography>
          <Stack />
        </div>
        <div className='w-[1px] h-[716px] shrink-0 border-[1px] border-content1'> </div>
      </div>

      <div
        className='pl-56 flex flex-row space-x-56 pr-24 overflow-x-scroll overflow-y-hidden'
        onScroll={handleScroll}
      >
        {competitions.map((competition, idx) => (
          <motion.div
            key={competition.id}
            initial={{ y: 500, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: page > 1 ? 0.1 * idx : 0.5 * idx, duration: 1 }}
          >
            <Link
              to={`${ROUTES.HOME.MAIN}/${modalityId}/competition/${competition.id}`}
              className='flex justify-center items-center'
            >
              <Trophy data={competition} />
            </Link>
          </motion.div>
        ))}

        {!!(isFetching && page > 1) &&
          Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className='flex justify-center items-center'>
              <div className='flex flex-col space-y-7 h-full items-center w-40'>
                <Skeleton className='w-[125px] h-[290px]' />
                <Skeleton className='h-9 w-16' />
                <Skeleton className='h-64 w-[2px]' />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

import {
  CompetitionDetailLoading,
  CompetitionMembers,
  CompetitionStats,
  Galleria,
  Gallery,
  TrophyData,
} from '@/components/competitions';
import { ROUTES } from '@/config';
import { Icon } from '@/core/icon';
import {
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselScrollBar,
} from '@/core/ui';
import { API } from '@/store/api';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const CompetitionDetail = () => {
  const { competitionId, modalityId } = useParams();
  const { i18n } = useTranslation();

  const {
    error,
    isLoading,
    isFetching,
    data: competition,
  } = API.competitionApi.useGetCompetitionByIdQuery(
    {
      competitionId: Number.parseInt(competitionId as string, 10),
      locale: i18n.language,
    },
    { skip: !competitionId || !i18n.language },
  );

  if (error) {
    return <p>Oh no, there was an error</p>;
  }

  if (isLoading || isFetching || !competition) {
    return <CompetitionDetailLoading />;
  }

  return (
    <div className='w-full h-full'>
      <Carousel opts={{ align: 'start' }}>
        <CarouselContent>
          <CarouselItem className='pl-0 -mr-1'>
            <TrophyData competition={competition} />
          </CarouselItem>
          <CarouselItem className='pl-0 -mr-1'>
            <CompetitionStats name={competition.name} stats={competition} />
          </CarouselItem>
          <CarouselItem className='pl-0 -mr-1'>
            <CompetitionMembers
              title={`${competition.name}`}
              squad={competition.squad}
              technical_team={competition.technical_team}
            />
          </CarouselItem>
          <CarouselItem className='pl-0 basis-[41%]'>
            <Galleria president={competition.president_info} competitionName={competition.name} />
          </CarouselItem>
          <CarouselItem className='pl-0 -ml-0.5'>
            <Gallery
              gallery={competition.gallery}
              highlight={competition.highlight}
              competitionName={competition.name}
            />
          </CarouselItem>
        </CarouselContent>
        <CarouselNext className='!bg-transparent disabled:bg-transparent right-0 disabled:hidden'>
          <Icon name='CHEVRON_RIGHT' className='w-10 h-10' />
        </CarouselNext>
        <CarouselPrevious className='!bg-transparent disabled:bg-transparent left-0 disabled:hidden '>
          <Icon name='CHEVRON_LEFT' className='w-10 h-10' />
        </CarouselPrevious>

        <Link
          to={`${ROUTES.HOME.MAIN}/${modalityId}/${ROUTES.COMPETITION.MAIN}`}
          className={'absolute top-14 right-20'}
        >
          <Button className='border-none flex px-7 py-8 bg-danger rounded-full items-center space-x-2'>
            <Icon className='h-5 w-5' name='CHEVRON_LEFT' />
            <Icon className='h-7 w-6' name='HOME' />
          </Button>
        </Link>

        <CarouselScrollBar />
      </Carousel>
    </div>
  );
};
export default CompetitionDetail;

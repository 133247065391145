import { envVars } from '@/config';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { competitionApi, competitionReducer } from './competition';
import { globalApi, globalReducer } from './global';
import { modalityApi, modalityReducer } from './modality';
import { oneCoreApi } from './onecore';

export const store = configureStore({
  reducer: {
    global: globalReducer,
    modality: modalityReducer,
    competition: competitionReducer,
    [modalityApi.reducerPath]: modalityApi.reducer,
    [globalApi.reducerPath]: globalApi.reducer,
    [competitionApi.reducerPath]: competitionApi.reducer,
    [oneCoreApi.reducerPath]: oneCoreApi.reducer,
  },
  devTools: !envVars.prod,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({ serializableCheck: false }).concat(
      globalApi.middleware,
      modalityApi.middleware,
      competitionApi.middleware,
      oneCoreApi.middleware,
    );
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ModalityState {
  modalityId: number;
}

const initialState: ModalityState = {
  modalityId: 1,
};

export const modalitySlice = createSlice({
  name: 'modality',
  initialState,
  reducers: {
    setModalityId: (state, action: PayloadAction<ModalityState['modalityId']>) => {
      state.modalityId = action.payload;
    },
  },
});

export const { setModalityId } = modalitySlice.actions;

export default modalitySlice.reducer;
